import React from "react"
import { VideoCategory, videos } from "../data/videos"
import CustomHelmet from "../components/CustomHelmet"
import Layout from "../components/layout"
import Videos from "../components/Videos"

const styles = require("./testimonios.module.scss")

const filteredVideos = videos.filter(v => VideoCategory.Testimonios === v.category)

const Testimonios = () => (
  <Layout>
    <CustomHelmet
      title={"Testimonios"}
      description={"Testimonios, opiniones y experiencias que comparten los estudiantes, acerca de la planeación de su viaje y experiencia desde Malta."}
      link={"/testimonios/"}
    />
    <div className={styles.testimonios}>
      <h1>Testimonios</h1>
      <div className={styles.headerExplain}>
        Conoce cómo ha sido la experiencia de nuestros asesorados
      </div>
    </div>
    <Videos videos={filteredVideos}/>
  </Layout>
)

export default Testimonios